// GENERAL
b, strong {
    font-weight: $font-weight-bold;
}

// BUTTONS
//.btn-warning {
//    color: $clr-text-light-primary;
//    &:hover {
//       color: $clr-text-light-primary; 
//    }
//} //To be revised in future version of Bootstrap https://github.com/twbs/bootstrap/issues/23418

// CARDS
.card {
    .card-header {
        font-weight: $font-weight-medium;
        font-family: $font-family-headings;
        background-color: $clr-card-head;
        color: $clr-text-light-primary;
        i {
            color: $clr-icon-light-inactive;
        }
        .card-header-toggle {
            i {
                transition: all 200ms ease-in-out;
            }
        }
        &:hover {
            .card-header-toggle {
                i {
                    color: $clr-icon-light-active;
                    transition: all 200ms ease-in-out;
                }
            }
        }
        .btn {
            i {
                transition: $btn-transition;
            }
            &:hover {
                i {
                    color: $clr-icon-light-active;
                    transition: $btn-transition;
                }
            }
        }
        .card-header-toggle-individual {
            i {
                transition: all 200ms ease-in-out;
                cursor: pointer;
                &:hover {
                    color: $clr-icon-light-active;
                    transition: all 200ms ease-in-out;
                }
            }
        }
    }   
    &.is-html {
        min-height: calc(100vh - 150px);
        iframe {
            border: 0;
            &.card-body {
                padding: 0;
            }
        }
    }
}

// FORMS
input {
    font-family: $font-family-sans-serif;
}

.form-group {
    label {
        color: $clr-text-dark-secondary;
        margin-bottom: .1rem;
    }
}

.custom-file{
    min-width: $custom-file-width;
    .custom-file-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 75px;
        &::after{
            transition: background-color 0.15s ease-in-out;
        }
    }
    &:hover {
        .custom-file-label {
            &::after {
                background-color: darken($link-color, 15%);
                transition: background-color 0.15s ease-in-out;
            }
        }
    }
}

// TABLES
.thead-light {
    i {
        color: $clr-icon-light-inactive;
        cursor: pointer;
        transition: $btn-transition;
    }
    .btn:hover {
        i {
            color: $clr-icon-light-active;
        }
    }
    i:hover, i.active {
        color: $clr-icon-light-active;
    }
    .input-group-text {
        i {
            color: $clr-icon-dark-active;
            cursor: default;
        }
    }
    th {
        font-weight: $font-weight-medium;
        font-family: $font-family-headings;
        white-space: nowrap;
    }
}
tbody {
    font-size: $font-size-sm;
    tr {
        td {
            .form-group {
                margin-bottom: 0;
                .input-group > .form-control {
                    flex: unset;
                    display: inline-block;
                    width: auto;
                    vertical-align: middle;
                }
                .btn-light {
                    i {
                        color: $clr-icon-dark-inactive;
                        transition: $btn-transition;
                    }
                    &:hover {
                        i {
                            color: $clr-icon-dark-active;
                        }
                    }
                }
            }
            pre {
                margin-bottom: 0;
            }
        }
    }
}

con-doc-process-layout {
    con-entity-list {
        .table {
                td {
                padding: 2px; 
                font-size: 0.70rem;
                font-weight: bold;
            }
        }
    }
}

// BADGES
.badge {
    border-radius: 0.25rem;
}

h1 , h2 , h3 , h4 , h5 , h6  {
    .badge {
        font-weight: $headings-font-weight;
    }
}

// MODAL
.modal-content {
    box-shadow: $shadow-7;
}

// ALERTS
.alert {
    .alert-link-icon {
        color: $clr-icon-dark-inactive;
        transition: $btn-transition;
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}

// TOOLTIPS
.tooltip {
    font-weight: $font-weight-bold;
}

// UTILITY
.cursor-pointer {
    cursor: pointer!important;
}
.cursor-default {
    cursor: default!important;
}